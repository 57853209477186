@import './shared-ui.scss';
@import './theme/button';
@import './theme/common';
@import './theme/content-maxwidth';
@import './theme/hr';
@import './theme/font';
@import './theme/ghost';
@import './theme/grid';
@import './theme/tag';
@import './theme/card';
@import './theme/markdown';

a {
  color: $accent;
  @each $name, $value in $color-map {
    &[color='#{$name}'] {
      @include font-color($name);
      text-decoration: none;

      &[color-fill] {
        @include background-color($name);
      }
    }
  }
  &:focus,
  &:hover {
    text-decoration: none;
  }
}
