/**
* @example
* 
**/
.ghost {
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #f6f7f8 60%,
    #edeef1 80%,
    #f6f7f8 100%
  );

  animation-duration: 20s;
  animation-name: placeholderShimmer;
  &.slow {
    animation-name: placeholderShimmerSlow;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -2468px 0;
  }

  100% {
    background-position: 2468px 0;
  }
}

@keyframes placeholderShimmerSlow {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.contact-impossible {
  pointer-events: none;
}
