@import '../shared-ui.scss';

button[graat-hp-button],
a[graat-hp-button] {
  @include background-color(graat);
  @include font(h7);
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;

  transition: background-color 0.2s, color 0.2s;

  outline: none;
  border: 0;
  border-radius: 4px;
  padding: 0 16px 0 16px;
  height: 48px;
  line-height: 48px;
  min-width: 232px;
  text-align: center;

  &:hover,
  &:focus {
    @include background-color(accent);
  }
  &.button-suffix-arrow-down {
    @include flex-row(center, center);
    @include media('xsmall', 'small') {
      @include flex-row(space-between, center);
    }
    &::after {
      $size: 1em;
      content: '';
      display: inline-block;
      background-image: url(https://cdn.graat.co.jp/assets/circle_arrow_down.svg);
      background-size: cover;
      background-repeat: no-repeat;
      width: $size;
      height: $size;
      margin-left: 8px;
    }
  }

  @each $name, $value in $color-map {
    &[color='#{$name}'] {
      @include background-color-focus($name);
    }
  }

  &[disabled] {
    @include background-color-focus(disabled);
  }
}

button[graat-hp-button-next],
a[graat-hp-button-next] {
  @include font(accent);
  @include elevation(3);
  @include flex-row(space-between, center, nowrap, false);
  text-decoration: none;

  box-sizing: border-box;

  outline: none;
  border-radius: 4px;
  padding: 0 16px;
  height: 80px;
  min-width: 272px;
  @include media('xsmall', 'small') {
    height: 40px;
  }

  &:hover,
  &:focus {
    @include elevation(6);
  }
  &::after {
    content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy4yNDUiIGhlaWdodD0iMTAuNzM2IiB2aWV3Qm94PSIwIDAgMTMuMjQ1IDEwLjczNiI+CiAgPHBhdGggZD0iTTYuNDQxLDkuMjg0LDkuMzI5LDYuNEgwVjQuMzQxSDkuMjM5TDYuMzUsMS40NTMsNy44LDBsNS40NDIsNS4zODZMNy44OTQsMTAuNzM2WiIgZmlsbD0iIzJlMzAyZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+Cjwvc3ZnPgo=);
  }
  &.reverse {
    @include flex-row(flex-end, center, nowrap, true);
    &::after {
      padding-right: 8px;
      content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAADaADAAQAAAABAAAACgAAAAA4IuaEAAAAYklEQVQoFWNgwAQGQKH9QCyAKYVdJAEo/B6I/wNxAxATBAlAFSDFILwAiAmCBKAKmIYGgqqBChKAGKaBKJoJqIFoDwPVooAEIA9mC4hNNEgAqqRYYwPR1gEVJgDxBSDG6VcAZdYfqs8nacYAAAAASUVORK5CYII=);
    }
  }

  @each $name, $value in $color-map {
    $on: map-get($value, on);
    &[color='#{$name}'] {
      @include background-color-focus($name);
      @if $on == $white {
        &::after {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  &[disabled] {
    @include background-color-focus(disabled);
  }
}
