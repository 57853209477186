$black: #2e302f;
$black-reticence: #666666;

$white: #fff;

$accent: #e0007f;

$divider-color: #ebebeb;

$color-map: (
  default: (
    color: $white,
    on: $black,
    focus: (
      color: $white,
      on: $black,
    ),
  ),
  disabled: (
    color: #dcdcdc,
    on: #a3a3a3,
    focus: (
      color: #dcdcdc,
      on: #a3a3a3,
    ),
  ),
  reticence: (
    color: $white,
    on: $black-reticence,
    focus: (
      color: $white,
      on: $black,
    ),
  ),
  main: (
    color: #008575,
    on: $white,
    focus: (
      color: $accent,
      on: $white,
    ),
  ),
  main-variant: (
    color: #006256,
    on: $white,
    focus: (
      color: $accent,
      on: $white,
    ),
  ),
  sub: (
    color: #6a08f4,
    on: $white,
    focus: (
      color: $accent,
      on: $white,
    ),
  ),
  accent: (
    color: $accent,
    on: $white,
    focus: (
      color: $black,
      on: $white,
    ),
  ),
  base-a: (
    color: #ecf2fa,
    on: $black,
    focus: (
      color: $accent,
      on: $white,
    ),
  ),
  base-b: (
    color: #eef7f9,
    on: $black,
    focus: (
      color: $accent,
      on: $white,
    ),
  ),
  base-c: (
    color: #f4f3f1,
    on: $black,
    focus: (
      color: $accent,
      on: $white,
    ),
  ),
  graat: (
    color: $black,
    on: $white,
    focus: (
      color: $accent,
      on: $white,
    ),
  ),
  error: (
    color: red,
    on: $white,
    focus: (
      color: red,
      on: $white,
    ),
  ),
);

@mixin font-color($color) {
  color: map-get(map-get($color-map, $color), color);
}

@mixin background-color($background, $alpha: 1) {
  color: map-get(map-get($color-map, $background), on);
  background-color: rgba(
    map-get(map-get($color-map, $background), color),
    $alpha
  );
}

@mixin border-color($color, $px: 1px, $style: solid, $suffix: null, $alpha: 1) {
  @if $suffix == top or $suffix == bottom or $suffix == left or $suffix == right
  {
    border-#{$suffix}: $px
      $style
      rgba(map-get(map-get($color-map, $color), color), $alpha);
  } @else if $suffix == null {
    border: $px
      $style
      rgba(map-get(map-get($color-map, $color), color), $alpha);
  }
}

@mixin background-color-focus($background) {
  @include background-color($background);

  &:focus,
  &:hover {
    $fmap: map-get(map-get($color-map, $background), focus);
    color: map-get($fmap, on);
    background-color: map-get($fmap, color);
  }
}
