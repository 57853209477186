@import '../shared-ui.scss';

/// @example
///   <div graat-hp-grid x3 gap-x4>
///
*[graat-hp-grid] {
  width: 100%;
  display: grid;
  justify-items: center;
  // gap: gap-x0 ~ gap-x4
  @for $i from 0 through 4 {
    &[gap-x#{$i}] {
      gap: #{$i * 8}px;
    }
    @include media(xsmall, small) {
      &[gap-x#{$i}] {
        gap: #{$i * 4}px;
      }
    }
  }

  // x1 ~ x5
  @for $i from 1 through 5 {
    &[x#{$i}] {
      grid-template-columns: repeat($i, 1fr);
    }
    @include media(small) {
      &[x#{$i}] {
        @if (($i - 1) > 0) {
          grid-template-columns: repeat(#{$i - 1}, 1fr);
        } @else {
          grid-template-columns: 1fr;
        }
      }
    }
    @include media(xsmall) {
      &[x#{$i}] {
        @if (($i - 2) > 0) {
          grid-template-columns: repeat(#{$i - 2}, 1fr);
        } @else {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  &[scroll-horizontally-w300] {
    padding-bottom: 4px;
    grid-auto-columns: $card-width-high-resolution;
    grid-auto-flow: column;

    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    &::-webkit-scrollbar {
      display: none;
    }
    & > * {
      scroll-snap-align: center;
      scroll-snap-stop: always;
    }
    @include media('xsmall', 'small') {
      grid-auto-columns: $card-width-low-resolution;
    }
  }
}
