@import '../shared-ui.scss';

*[graat-hp-tag] {
  @include font(reticence);
  @include elevation(3);
  cursor: pointer;

  transition: box-shadow 0.2s;
  display: inline-block;

  text-align: center;
  outline: none;
  border-radius: 4px;
  padding: 0 16px 0 16px;
  height: 26px;
  line-height: 26px;

  &:hover,
  &:focus {
    @include elevation(6);
  }
  @each $name, $value in $color-map {
    &[color='#{$name}'] {
      @include background-color($name);
    }
  }

  &[tag-layout-only] {
    cursor: auto;
    &:hover,
    &:focus {
      @include elevation(3);
    }
  }

  @each $name,
    $value in (x3: h7, x4: h6, x5: h5, x6: h7, x7: h4, x8: page-title, x9: h3)
  {
    &[#{$name}] {
      @include font($value, 1.6em);
      height: 1.6em;
    }
  }
}
