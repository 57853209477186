@use 'sass:math';

$margin-top: 3em;

markdown {
  @include font(blog);
  img {
    width: 100%;
  }

  h1 {
    font-size: #{math.div(28, 16)}rem;
    line-height: 1.4em;
    margin-top: $margin-top;
  }
  h2 {
    font-size: #{math.div(26, 16)}rem;
    line-height: 1.4em;
    margin-top: $margin-top;
  }
  h3 {
    font-size: #{math.div(24, 16)}rem;
    line-height: 1.6em;
    margin-top: $margin-top;
  }
  h4 {
    font-size: #{math.div(22, 16)}rem;
    line-height: 1.6em;
    margin-top: $margin-top;
  }
  h5,
  h6 {
    font-size: #{math.div(20, 16)}rem;
    line-height: 1.6em;
    margin-top: $margin-top;
  }

  code:not([class*='language-']) {
    @include font-color(graat);
    background-color: #f5f2f0;
    text-shadow: 0 1px white;
    border-radius: 0.3em;
    padding: 0.1em;
    word-break: break-all;
  }

  blockquote {
    @include background-color(base-b);
    margin: 0;
    padding: 25px 36px;
  }

  .md-text-nowrap {
    white-space: nowrap;
  }
}

graat-hp-cs-template {
  section.summary {
    article {
      markdown {
        p {
          @include font(text);
        }
      }
    }
  }
}
