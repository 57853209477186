/**
* @example:
*  @include first-text-underline($width: 40px);
**/
@mixin first-text-underline($width: 40px, $color: main) {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: $width;
    bottom: 0px;
    left: 0px;
    border-bottom: 2px solid map-get(map-get($color-map, $color), color);
  }
}
