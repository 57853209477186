// @see https://fonts.google.com/specimen/Didact+Gothic
@use 'sass:math';

%letter-gothic-std-alternative {
  font-family: 'Didact Gothic', monospace, sans-serif;
}

// https://fonts.google.com/specimen/Tangerine
%aesthetic-webfont {
  font-family: 'Tangerine', cursive;
}

$fonts: (
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7,
  blog,
  page-title,
  accent,
  text,
  small,
  reticence,
  reticence-bold
);

/**
* https://material.io/design/typography/the-type-system.html#type-scale
* @example
*  @include font(text);
**/
@mixin font($size: page-title, $em: 2em) {
  $font-size: (
    h1: math.div(45, 16),
    h2: math.div(40, 16),
    h3: math.div(36, 16),
    h4: math.div(26, 16),
    h5: math.div(22, 16),
    h6: math.div(20, 16),
    h7: math.div(18, 16),
    blog: math.div(18, 16),
    page-title: math.div(30, 16),
    accent: math.div(16, 16),
    text: math.div(16, 16),
    small: math.div(14, 16),
    reticence-bold: math.div(12, 16),
    reticence: math.div(12, 16),
  );

  $font-weight: (
    h1: bold,
    h2: bold,
    h3: bold,
    h4: bold,
    h5: bold,
    h6: bold,
    h7: bold,
    blog: 300,
    page-title: bold,
    text: normal,
    accent: bold,
    small: normal,
    reticence-bold: bold,
    reticence: 300,
  );

  $type-size: map-get($font-size, $size);
  $type-weight: map-get($font-weight, $size);
  @if ($type-size == null) {
    @error " get-fonts() のパラメーターが正しくありません。";
  }

  font-size: #{$type-size}rem;
  line-height: $em;
  font-weight: $type-weight;
}
