/// @example
///   <div font-h6> h6 size font</div>
@each $font in $fonts {
  *[font-#{$font}] {
    @include font($font);
  }
}

h1,
h2 {
  line-height: 1.2em;
}

h3,
h4 {
  line-height: 1.4em;
}

h5,
h6 {
  line-height: 1.6em;
}
