/* You can add global styles to this file, and also import other style files */
@import 'normalize.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'theme';

html {
  font-family: 'Noto Sans JP', 'Lato', 'Hiragino Kaku Gothic ProN', Meiryo,
    sans-serif;
  font-size: 16px;
  line-height: 2rem;

  @include media('xsmall', 'small') {
    font-size: 14px;
  }
}
