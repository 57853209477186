@import '../shared-ui.scss';

*[graat-hp-card] {
  @include elevation();

  cursor: pointer;
  padding: 48px;

  @include media('xsmall', 'small') {
    padding: 24px;
  }

  &:hover,
  &:focus {
    @include elevation(6);
  }

  &[tag-layout-only] {
    cursor: auto;
    &:hover,
    &:focus {
      @include elevation();
    }
  }
}
