@import '../shared-ui.scss';

.main-section__width {
  max-width: $content-max-width;
  margin-left: auto;
  margin-right: auto;
}

.blog-section__width {
  max-width: $blog-max-width;
  margin-left: auto;
  margin-right: auto;
}
