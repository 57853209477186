$default-high-horizontal-padding: 64px;
$default-low-horizontal-padding: 24px;

/** 
* @example: 
*  @include set-horizontal-padding($left:true, $right:true, $high-resolution: 64px, $low-resolution: 24px);
*  @include set-horizontal-padding($left:true, $right:true);
*  @include set-horizontal-padding($left:true);
*/
@mixin set-horizontal-padding(
  $left: false,
  $right: false,
  $high-resolution: $default-high-horizontal-padding,
  $low-resolution: $default-low-horizontal-padding
) {
  @if $left == flase and $right == false {
    @error "$left, $rightの両方がflaseは設定できません。引数を与えてください";
  }
  padding-left: if($left, $high-resolution, inherit);
  padding-right: if($right, $high-resolution, inherit);

  @include media(xsmall, small) {
    padding-left: if($left, $low-resolution, inherit);
    padding-right: if($right, $low-resolution, inherit);
  }
}

/** 
* @example: 
*  @include set-vertical-padding($top:true, $bottom:true);
*  @include set-vertical-padding($top:true);
*/
@mixin set-vertical-padding(
  $top: false,
  $bottom: false,
  $high-resolution: $default-high-horizontal-padding,
  $low-resolution: $default-low-horizontal-padding
) {
  @if $top == flase and $bottom == false {
    @error "$top, $bottomの両方がflaseは設定できません。引数を与えてください";
  }
  padding-top: if($top, $high-resolution, inherit);
  padding-bottom: if($bottom, $high-resolution, inherit);

  @include media(xsmall, small) {
    padding-top: if($top, $low-resolution, inherit);
    padding-bottom: if($bottom, $low-resolution, inherit);
  }
}

/** 
* @example: 
*  @include set-vertical-padding($top:true, $bottom:true);
*  @include set-vertical-padding($top:true);
*/
%set-headder-padding {
  padding-top: 240px;

  @include media(xsmall, small) {
    padding-top: 80px;
  }
}
